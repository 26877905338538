<template>
    <!-- eslint-disable vue/no-v-html -->
    <div
        v-if="content"
        ref="richText"
        class="rich-text"
        v-html="content"
    />
    <!-- eslint-enable -->
    <div
        v-else
        class="article"
    >
        <slot />
    </div>
</template>

<script setup>
import { nextTick } from 'vue';
import { useWordDefinitions } from '~/composables/useWordDefinitions';

const richText = ref();

const props = defineProps({
    content: {
        type: String,
        default: ''
    }
});

onMounted(async() => {
    if (!process.client) {
        return;
    }

    await nextTick();
    useWordDefinitions(richText.value);
});
</script>

<style lang="less" src="./RichText.less"></style>
