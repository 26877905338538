import WordDefinitionStructureQuery from '~/graphql/queries/word-definition/structure.graphql';
import { useQuery } from '~/composables/villus';
import { useWordDefinitionsStore } from '~/store/wordDefinitions';

const wordDefinitionURIs = {
    nl: 'woordenlijst',
    en: 'word-list'
};

export const useWordDefinitions = (container) => {
    const wordDefinitionsStore = useWordDefinitionsStore();
    const nuxtApp = useNuxtApp();
    const config = unref(useRuntimeConfig());

    if (!process.client) {
        return;
    }

    const locale = nuxtApp.$i18n.locale.value;
    let localePrefix = '';

    if (locale !== 'nl') {
        localePrefix = 'en/';
    }

    const wordlistBaseURL = `${config.public.baseURL}/${localePrefix}${wordDefinitionURIs[locale]}/`;
    const wordDefinitionAnchors = container.querySelectorAll(`a[href^='${wordlistBaseURL}']`);

    wordDefinitionAnchors.forEach(async(element) => {
        const definitionURI = element.attributes.href.value.replace(wordlistBaseURL, '');
        const storeKey = `${locale}/${definitionURI}`;
        let definition;

        if (wordDefinitionsStore.getDefinition(storeKey)) {
            definition = wordDefinitionsStore.getDefinition(storeKey);
        } else {
            const variables = {
                uri: `${wordDefinitionURIs[locale]}/${definitionURI}`
            };

            const { data } = await useQuery(WordDefinitionStructureQuery, variables);
            definition = unref(data)?.data?.entry?.wordDefinition;
            wordDefinitionsStore.addDefinition({ key: storeKey, value: definition });
        }

        element.setAttribute('aria-label', definition);
        element.setAttribute('data-has-definition', true);
    });
};
