import { defineStore } from 'pinia';

export const useWordDefinitionsStore = defineStore('word-definitions-store', {
    state: () => {
        return {
            definitions: new Map()
        };
    },

    getters: {
        definition(key) {
            if (this.definitions[key]) {
                return this.definitions[key];
            }
            return null;
        }
    },

    actions: {
        addDefinition({ key, value }) {
            this.definitions.set(key, value);
        },

        getDefinition(key) {
            if (this.definitions.has(key)) {
                return this.definitions.get(key);
            }
            return null;
        }
    }
});
